<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.drilling_log') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('irriPumpInformation.pump_id')"
            label-for="pump_id"
            >
            <b-form-select
              plain
              v-model="search.pump_id"
              :options="pumpDropdownList"
              id="pump_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.drilling_log_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button> -->
            <router-link to="/irrigation/pump-information/drilling-log-entry-form" :class="'btn btn_add_new'">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      {{ data.item.division_name_bn }}
                    </template>
                    <template v-slot:cell(district_name_bn)="data">
                      {{ data.item.district_name_bn }}
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">{{ data.item.district_name }}</span>
                    </template>
                    <template v-slot:cell(org_name_bn)="data">
                      {{ data.item.org_name_bn }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFromNow }}</span>
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(pump_id)="data">
                      {{ $n(data.item.pump_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge pill variant="danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</b-badge>
                      <b-badge pill variant="primary" v-else>{{ $t('globalTrans.active') }}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <router-link :to="'/irrigation/pump-information/drilling-log-entry-form?id='+data.item.id" class="btn btn-info btn-sm mr-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                      <b-button :variant="data.item.status ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)" title="Active/Inactive">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-detail @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <router-link class="btn_table_action table_action_edit" :to="'/irrigation/pump-information/drilling-log-entry-form?id='+data.item.id" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill"></i></router-link>
                      <a class="btn_table_action" :class="data.item.status ? ' table_action_toggle' : ' table_action_status'" @click="remove(data.item)" title="Active/Inactive">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <EntryForm :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExport(item)" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.print') }}
        </b-button>
        <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
  </b-container>
</template>
<script>
import EntryForm from './EntryForm'
import DetailModal from './DetailModal'
import { drillingLogList, drillingLogToggleStatus } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModal, EntryForm
  },
  data () {
    return {
      search: {
        pump_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0'
      },
      rows: [],
      item: '',
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('irriPumpInformation.drilling_log_list') : this.$t('irriPumpInformation.drilling_log_list') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('irriPumpInformation.drilling_log') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.project'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.pump_id'), class: 'text-center' },
          { label: this.$t('org_pro_division.division'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('org_pro_union.union'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'project_name_bn' },
          { key: 'pump_id' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'union_name_bn' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'project_name' },
          { key: 'pump_id' },
           { key: 'division_name' },
           { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'union_name' },
          { key: 'created_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
        } else {
          return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
        }
      })
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.pumpDropdownList = this.getPumplist(newVal)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'search.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 0) {
      this.search = Object.assign({}, this.search, {
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 0) {
        this.search = Object.assign({}, this.search, {
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, drillingLogToggleStatus, item)
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(irriSchemeServiceBaseUrl, drillingLogList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const projectList = this.$store.state.IrriConfig.commonObj.projectList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const projectObject = projectList.find(project => project.value === item.project_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
          upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
        }
        const unionData = {
          union_name: unionObject !== undefined ? unionObject.text_en : '',
          union_name_bn: unionObject !== undefined ? unionObject.text_bn : ''
        }
        const projectData = {
          project_name: projectObject !== undefined ? projectObject.text_en : '',
          project_name_bn: projectObject !== undefined ? projectObject.text_bn : ''
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, projectData)
      })
    },
    getPumplist (upazillaOrUnionId) {
      this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => (pumpInfo.upazilla_id === upazillaOrUnionId || pumpInfo.union_id === upazillaOrUnionId) && pumpInfo.status === 0)
      return this.pumpInformationList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.pump_id }
        } else {
          return { value: obj.id, text: obj.pump_id }
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    },
    pdfExport () {
      const reportTitle = this.$t('irriPumpInformation.drilling_log') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(reportTitle, this,
        this.item
      )
    }
  }
}
</script>
