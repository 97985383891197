import i18n from '@/i18n'
import Store from '@/store'
// import { Time } from 'highcharts'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center' }
    ]

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.drilling_log'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const allRowsHead = [
        [
        { text: thisObject.$t('irriPumpInformation.project'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.project_name : data.project_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.pump_id'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.pump_id, { useGrouping: false }), alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: thisObject.$t('complain.division'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.division_name : data.division_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('complain.district'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.district_name : data.district_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('complain.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.upazilla_name : data.upazilla_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('complain.union'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.union_name : data.union_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.mouza_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.mouza_no, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.jl_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.jl_no, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.plot_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.plot_no, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.well_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.well_no, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.engineer_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.engineer_name : data.engineer_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.drilling_contractor_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.drilling_contractor_name : data.drilling_contractor_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.date_started'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.drilling_start_date !== null ? thisObject.$d(new Date(data.drilling_start_date)) : '', alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.time'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.drilling_start_time, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.date_completed'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.drilling_complete_date !== null ? thisObject.$d(new Date(data.drilling_complete_date)) : '', alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.time'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.drilling_complete_time, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.rod_length'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.rod_length, { useGrouping: false }), alignment: 'left', style: 'search' },
      {},
      {},
      {}
    ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '20%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      // drilling_time_log
      pdfContent.push([
        { text: thisObject.$t('irriPumpInformation.drilling_time_log'), style: 'header3', alignment: 'center', decoration: 'underline' }
      ])

      const allRowsHeadDrillingLogTime = [
        [
          { text: thisObject.$t('irriPumpInformation.rod'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: i18n.locale === 'en' ? data.rod : data.rod_bn, alignment: 'left', style: 'search' },
          { text: thisObject.$t('irriPumpInformation.from'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: data.rod_from, alignment: 'left', style: 'search' }
        ]
      ]

      allRowsHeadDrillingLogTime.push([
        { text: thisObject.$t('irriPumpInformation.to'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: data.rod_to, alignment: 'left', style: 'search' },
        {},
        {},
        {}
      ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHeadDrillingLogTime
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      // graphic_log
      pdfContent.push([
        { text: thisObject.$t('irriPumpInformation.graphic_log'), style: 'header3', alignment: 'center', decoration: 'underline' }
      ])

      const allRowsHeadGraphicLog = [
        [
          { text: thisObject.$t('irriPumpInformation.thickness'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: thisObject.$n(data.thickness, { useGrouping: false }), alignment: 'left', style: 'search' },
          { text: thisObject.$t('irriPumpInformation.date'), alignment: 'right', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: data.graphic_log_date !== null ? thisObject.$d(new Date(data.graphic_log_date)) : '', alignment: 'left', style: 'search' }
        ]
      ]

      allRowsHeadGraphicLog.push([
        { text: thisObject.$t('irriPumpInformation.from'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: data.thickness_from, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.to'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: data.thickness_to, alignment: 'left', style: 'search' }
      ])

      allRowsHeadGraphicLog.push([
        { text: thisObject.$t('irriPumpInformation.description_of_formation'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.description : data.description_bn, alignment: 'left', style: 'search' },
        {},
        {},
        {}
      ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHeadGraphicLog
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      // Survery Note
      // pdfContent.push({ text: thisObject.$t('pump_install.survey_note'), style: 'header3', alignment: 'center', decoration: 'underline' })
      // const headList = [
      //   { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
      //   { text: thisObject.$t('pump_install.note'), style: 'th', alignment: 'center' }
      // ]

      // const allRowsSurveyNote = [headList]
      // data.survey_notes.map((item, index) => {
      //   const newRow = [
      //     { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
      //     { text: (i18n.locale === 'en') ? item.note : item.note_bn, style: 'td', alignment: 'center' }
      //   ]
      //   allRowsSurveyNote.push(newRow)
      // })

      // pdfContent.push({
      //   table: {
      //     headerRows: 1,
      //     widths: ['12%', '*'],
      //     body: allRowsSurveyNote
      //   }
      // })

        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
