<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.drilling_log') }}</h5>
                          <b-table striped small bordered hover :items="location_columns"  thead-class="hidden_header">
                            <template v-slot:cell(drilling_start_time)="data">
                              <span>{{ data.item.drilling_start_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(drilling_complete_time)="data">
                              <span>{{ data.item.drilling_complete_time | dateFormatTime }}</span>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.drilling_time_log') }}</h5>
                          <b-table striped small bordered hover :items="drilling_time_log_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.graphic_log') }}</h5>
                          <b-table striped small bordered hover :items="graphic_log_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.drillingLog = this.item
  },
  data () {
    return {
      drillingLog: '',
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.project'), val: this.drillingLog.project_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.drillingLog.pump_id) },
          { key: this.$t('complain.division'), val: this.drillingLog.division_name_bn, key1: this.$t('complain.district'), val1: this.drillingLog.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.drillingLog.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.drillingLog.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.$n(this.drillingLog.mouza_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.drillingLog.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.drillingLog.plot_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.well_no'), val1: this.drillingLog.well_no },
          { key: this.$t('irriPumpInformation.engineer_name'), val: this.drillingLog.engineer_name_bn, key1: this.$t('irriPumpInformation.drilling_contractor_name'), val1: this.drillingLog.drilling_contractor_name_bn },
          { key: this.$t('irriPumpInformation.date_started'), val: this.$d(new Date(this.drillingLog.drilling_start_date)), key1: this.$t('irriPumpInformation.time'), val1: this.drillingLog.drilling_start_time },
          { key: this.$t('irriPumpInformation.date_completed'), val: this.$d(new Date(this.drillingLog.drilling_complete_date)), key1: this.$t('irriPumpInformation.time'), val1: this.drillingLog.drilling_complete_time },
          { key: this.$t('irriPumpInformation.rod_length'), val: this.$n(this.drillingLog.rod_length, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('irriPumpInformation.project'), val: this.drillingLog.project_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.drillingLog.pump_id },
            { key: this.$t('complain.division'), val: this.drillingLog.division_name, key1: this.$t('complain.district'), val1: this.drillingLog.district_name },
            { key: this.$t('complain.upazila'), val: this.drillingLog.upazilla_name, key1: this.$t('complain.union'), val1: this.drillingLog.union_name },
            { key: this.$t('irriPumpInformation.mouza_no'), val: this.drillingLog.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.drillingLog.jl_no },
            { key: this.$t('irriPumpInformation.plot_no'), val: this.drillingLog.plot_no, key1: this.$t('irriPumpInformation.well_no'), val1: this.drillingLog.well_no },
            { key: this.$t('irriPumpInformation.engineer_name'), val: this.drillingLog.engineer_name, key1: this.$t('irriPumpInformation.drilling_contractor_name'), val1: this.drillingLog.drilling_contractor_name },
            { key: this.$t('irriPumpInformation.date_started'), val: this.$d(new Date(this.drillingLog.drilling_start_date)), key1: this.$t('irriPumpInformation.time'), val1: this.drillingLog.drilling_start_time },
            { key: this.$t('irriPumpInformation.date_completed'), val: this.$d(new Date(this.drillingLog.drilling_complete_date)), key1: this.$t('irriPumpInformation.time'), val1: this.drillingLog.drilling_complete_time },
            { key: this.$t('irriPumpInformation.rod_length'), val: this.$n(this.drillingLog.rod_length, { useGrouping: false }) }
          ]
      }
    },
    drilling_time_log_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.rod'), val: this.drillingLog.rod_bn, key1: this.$t('irriPumpInformation.from'), val1: this.drillingLog.rod_from, key2: this.$t('irriPumpInformation.to'), val2: this.drillingLog.rod_to }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.rod'), val: this.drillingLog.rod, key1: this.$t('irriPumpInformation.from'), val1: this.drillingLog.rod_from, key2: this.$t('irriPumpInformation.to'), val2: this.drillingLog.rod_to }
          ]
      }
    },
    graphic_log_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.thickness'), val: this.$n(this.drillingLog.thickness, { useGrouping: false }), key1: this.$t('irriPumpInformation.date'), val1: this.drillingLog.graphic_log_date !== null ? this.$d(new Date(this.drillingLog.graphic_log_date)) : '' },
          { key: this.$t('irriPumpInformation.from'), val: this.drillingLog.thickness_from, key1: this.$t('irriPumpInformation.to'), val1: this.drillingLog.thickness_to },
          { key: this.$t('irriPumpInformation.description_of_formation'), val: this.drillingLog.description_bn }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.thickness'), val: this.$n(this.drillingLog.thickness, { useGrouping: false }), key1: this.$t('irriPumpInformation.date'), val1: this.drillingLog.graphic_log_date !== null ? this.$d(new Date(this.drillingLog.graphic_log_date)) : '' },
          { key: this.$t('irriPumpInformation.from'), val: this.drillingLog.thickness_from, key1: this.$t('irriPumpInformation.to'), val1: this.drillingLog.thickness_to },
          { key: this.$t('irriPumpInformation.description_of_formation'), val: this.drillingLog.description }
          ]
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
