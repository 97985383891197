<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle  class="text-center">
              <div class="text-center">
                <h5 class="text-white font-weight-bold"><i class="ri-edit-line"></i> {{ $t('irriPumpInformation.drilling_log_entry') }}</h5>
              </div>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <div class="border border-gray mb-3">
                    <b-row class="p-2">
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="organization"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="drillingLogEntry.org_id"
                              :options="organizationList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Division" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="drillingLogEntry.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="District" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                v-model="drillingLogEntry.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="upazila_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="drillingLogEntry.upazilla_id"
                              :options="upazilaList"
                              id="upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Union" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="union_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="drillingLogEntry.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="engineer name en" vid="engineer_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="engineer_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.engineer_name_en') }}
                          </template>
                          <b-form-input
                            id="engineer_name"
                            v-model="drillingLogEntry.engineer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="drilling contractor name en" vid="drilling_contractor_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_contractor_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_contractor_name_en') }}
                          </template>
                          <b-form-input
                            id="drilling_contractor_name"
                            v-model="drillingLogEntry.drilling_contractor_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="date started" vid="drilling_start_date">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_start_date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.date_started') }}
                          </template>
                          <b-form-input class="form-control"
                            v-model="drillingLogEntry.drilling_start_date"
                            :placeholder="$t('globalTrans.select_date')"
                            id="datepicker"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="date completed" vid="drilling_complete_date">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_complete_date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.date_completed') }}
                          </template>
                          <b-form-input class="form-control"
                            v-model="drillingLogEntry.drilling_complete_date"
                            :placeholder="$t('globalTrans.select_date')"
                            id="datepicker"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="project" vid="project_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="project_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.project')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                v-model="drillingLogEntry.project_id"
                                :options="projectList"
                                id="project_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Pump Id" vid="pump_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pump_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('irriPumpInformation.pump_id') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="drillingLogEntry.pump_id"
                                :options="pumpDropdownList"
                                id="pump_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Mouza No" vid='mouza_no' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mouza_no"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                  {{ $t('irriPumpInformation.mouza_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="mouza_no"
                                v-model="drillingLogEntry.mouza_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                          <ValidationProvider name="Jl No" vid='jl_no' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="jl_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.jl_no')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="jl_no"
                              v-model="drillingLogEntry.jl_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Plot No" vid='plot_no' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="plot_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.plot_no')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="plot_no"
                              v-model="drillingLogEntry.plot_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="well No" vid="well_no" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="well_no"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.well_no') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="well_no"
                            v-model="drillingLogEntry.well_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="engineer name Bn" vid="engineer_name_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="engineer_name_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.engineer_name_bn') }}
                          </template>
                          <b-form-input
                            id="engineer_name_bn"
                            v-model="drillingLogEntry.engineer_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="drilling contractor name bn" vid="drilling_contractor_name_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_contractor_name_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.drilling_contractor_name_bn') }}
                          </template>
                          <b-form-input
                            id="drilling_contractor_name_bn"
                            v-model="drillingLogEntry.drilling_contractor_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="start time" vid="drilling_start_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_start_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.time') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="drillingLogEntry.drilling_start_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="end time" vid="drilling_complete_time">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="drilling_complete_time"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.time') }}
                          </template>
                          <b-form-input class="form-control"
                            type="time"
                            v-model="drillingLogEntry.drilling_complete_time"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize p-1">{{ $t('irriPumpInformation.logged_by') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="rod length" vid="rod_length">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="rod_length"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.rod_length') }}
                          </template>
                          <b-form-input
                            type="number"
                            id="rod_length"
                            v-model="drillingLogEntry.rod_length"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.drilling_time_log') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="rod" vid="rod">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="rod"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.rod_en') }}
                          </template>
                          <b-form-input
                            id="rod"
                            v-model="drillingLogEntry.rod"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="rod bn" vid="rod_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="rod_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.rod_bn') }}
                          </template>
                          <b-form-input
                            id="rod_bn"
                            v-model="drillingLogEntry.rod_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="from" vid="rod_from">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="rod_from"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="rod_from"
                            v-model="drillingLogEntry.rod_from"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="to" vid="rod_to">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="rod_to"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="rod_to"
                            v-model="drillingLogEntry.rod_to"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="border border-gray mb-3">
                    <b-row>
                      <b-col lg="12" sm="12" class="mb-4">
                        <h5 class="font-weight-bold text-capitalize text-center bg-dark p-1">{{ $t('irriPumpInformation.graphic_log') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="thickness" vid="thickness">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="thickness"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.thickness') }}
                          </template>
                          <b-form-input
                            id="thickness"
                            type="number"
                            min="0"
                            v-model="drillingLogEntry.thickness"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="date completed" vid="graphic_log_date">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="graphic_log_date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.date') }}
                          </template>
                          <b-form-input class="form-control"
                            v-model="drillingLogEntry.graphic_log_date"
                            :placeholder="$t('globalTrans.select_date')"
                            id="datepicker"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="from" vid="thickness_from">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="thickness_from"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.from') }}
                          </template>
                          <b-form-input
                            id="thickness_from"
                            v-model="drillingLogEntry.thickness_from"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="to" vid="thickness_to">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="thickness_to"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.to') }}
                          </template>
                          <b-form-input
                            id="thickness_to"
                            v-model="drillingLogEntry.thickness_to"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="description of ormation" vid="description">
                          <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="description"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.description_of_formation') }}
                          </template>
                          <b-form-textarea
                            id="description"
                            v-model="drillingLogEntry.description"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="description of formation_bn" vid="description_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="description_bn"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.description_of_formation_bn') }}
                          </template>
                          <b-form-textarea
                            id="description_bn"
                            v-model="drillingLogEntry.description_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row class="mt-3">
                      <b-col></b-col>
                      <b-col>
                        <button  class="btn btn-primary btn-block" type="submit">{{ saveBtnName }}</button>
                      </b-col>
                      <b-col></b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { drillingLogStore, drillingLogUpdate, drillingLogShow } from '../../api/routes'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'

export default {
  prop: ['id', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    this.authUserData = this.authUser
    const id = this.$route.query.id
    if (id) {
      this.getData()
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.drillingLogEntry = Object.assign({}, this.drillingLogEntry, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.drillingLogEntry = Object.assign({}, this.drillingLogEntry, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  data () {
    return {
      authUserData: {},
      schemeTypeListData: [],
      loading: false,
      drillingLogEntry: {
          org_id: '15',
          pump_id: '0',
          division_id: '0',
          district_id: '0',
          upazilla_id: '0',
          union_id: '0',
          mouza_no: '',
          jl_no: '',
          project_id: '0',
          well_no: '',
          engineer_name: '',
          engineer_name_bn: '',
          drilling_contractor_name: '',
          drilling_contractor_name_bn: '',
          drilling_start_date: '',
          drilling_start_time: '',
          drilling_complete_date: '',
          drilling_complete_time: '',
          logged_by: '',
          rod_length: '',
          rod: '',
          rod_bn: '',
          rod_from: '',
          rod_to: '',
          thickness: '',
          graphic_log_date: '',
          thickness_from: '',
          thickness_to: '',
          description: '',
          description_bn: ''
      },
      drillingEditData: {},
      pumpInformationList: [],
      pumpInfo: {},
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      schManDistrictList: [],
      schManUpazilaList: [],
      schManUnionList: [],
      pumpDistrictList: [],
      pumpUpazilaList: [],
      pumpUnionList: [],
      farmer_id: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    saveBtnName () {
      return this.farmer_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    projectList () {
      const projectList = this.$store.state.IrriConfig.commonObj.projectList
      return projectList.filter(project => project.org_id === 15)
    }
  },
  watch: {
    'drillingLogEntry.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'drillingLogEntry.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'drillingLogEntry.upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'drillingLogEntry.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'drillingLogEntry.pump_id': function (newVal, oldVal) {
       this.getpumpSingleInfo(newVal)
    }
  },
  methods: {
    getData () {
     RestApi.getData(irriSchemeServiceBaseUrl, drillingLogShow + this.$route.query.id).then(response => {
        this.drillingLogEntry = response.data
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.$route.query.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${drillingLogUpdate}/${this.$route.query.id}`, this.drillingLogEntry)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, drillingLogStore, this.drillingLogEntry)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          setTimeout(async () => {
            this.$router.push('/irrigation/pump-information/drilling-log')
          }, 500)
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getPumplist (upazillaOrUnionId) {
      this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => (pumpInfo.upazilla_id === upazillaOrUnionId || pumpInfo.union_id === upazillaOrUnionId) && pumpInfo.status === 0)
      return this.pumpInformationList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: this.$n(obj.pump_id, { useGrouping: false }) }
        } else {
          return { value: obj.id, text: obj.pump_id }
        }
      })
    },
    getpumpSingleInfo (pumpId) {
      this.pumpInfo = this.pumpInformationList.find(pumpInfo => pumpInfo.id === pumpId)
      this.drillingLogEntry.mouza_no = this.pumpInfo.mouza_no
      this.drillingLogEntry.jl_no = this.pumpInfo.jl_no
      this.drillingLogEntry.plot_no = this.pumpInfo.plot_no
    }
  }
}
</script>
